import type { AssignTaskUser } from '~/models/AssignTask'
import { computed, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { UserRole } from '~/models/User/UserRole'
import { TaskStatus } from '~/models/AssignTask'

const mockData = ref<AssignTaskUser[]>([
  {
    userId: '1',
    status: TaskStatus.Open,
    firstName: 'Sokrates',
    lastName: 'Filosof',
    fullName: 'Sokrates Filosof',
    role: UserRole.Student,
  },
  {
    userId: '2',
    status: TaskStatus.Closed,
    firstName: 'Pontus',
    lastName: 'Pilatus',
    fullName: 'Pontus Pilatus',
    role: UserRole.Student,
  },
  {
    userId: '3',
    status: TaskStatus.Open,
    firstName: 'Platon',
    lastName: 'Platus',
    fullName: 'Platon Platus',
    role: UserRole.Student,
  },
  {
    userId: '4',
    status: TaskStatus.Open,
    firstName: 'Philosophy',
    lastName: 'Man',
    fullName: 'Philosophy Man',
    role: UserRole.Student,
  },
  {
    userId: '5',
    status: TaskStatus.Closed,
    firstName: 'Aristotle',
    lastName: 'Philosopher',
    fullName: 'Aristotle Philosopher',
    role: UserRole.Student,
  },
  {
    userId: '6',
    status: TaskStatus.Open,
    firstName: 'Alexander',
    lastName: 'The Great',
    fullName: 'Alexander The Great',
    role: UserRole.Student,
  },
  {
    userId: '7',
    status: TaskStatus.Closed,
    firstName: 'Marcus',
    lastName: 'Aurelius',
    fullName: 'Marcus Aurelius',
    role: UserRole.Student,
  },
  {
    userId: '8',
    status: TaskStatus.Open,
    firstName: 'Julius',
    lastName: 'Caesar',
    fullName: 'Julius Caesar',
    role: UserRole.Student,
  },
  {
    userId: '9',
    status: TaskStatus.Closed,
    firstName: 'Cleopatra',
    lastName: 'Queen',
    fullName: 'Cleopatra Queen',
    role: UserRole.Student,
  },
  {
    userId: '10',
    status: TaskStatus.Open,
    firstName: 'Hannibal',
    lastName: 'Barca',
    fullName: 'Hannibal Barca',
    role: UserRole.Student,
  },
  {
    userId: '11',
    status: TaskStatus.Closed,
    firstName: 'Leonidas',
    lastName: 'Spartan',
    fullName: 'Leonidas Spartan',
    role: UserRole.Student,
  },
])

export function useAssignTaskUsers(taskId: string) {
  const queryClient = useQueryClient()
  const queryKey = computed(() => ['assigned_task_users', taskId])

  const {
    data: users,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: (): AssignTaskUser[] => mockData.value,
    staleTime: Infinity,
  })

  const updateUsers = useMutation({
    mutationFn: async (users: AssignTaskUser[]) => console.log(users),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const updateUser = useMutation({
    mutationFn: async (user: AssignTaskUser) => {
      user.status = user.status === TaskStatus.Open
        ? TaskStatus.Closed
        : TaskStatus.Open
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  // const updateUser = useMutation({
  //   mutationFn: async (user: AssignTaskUser) => {
  //     user.status = user.status === TaskStatus.Open
  //       ? TaskStatus.Closed
  //       : TaskStatus.Open
  //   },
  //   onMutate: async (updatedUser: AssignTaskUser) => {
  //     await queryClient.cancelQueries({ queryKey })
  //     const previousUsers = queryClient.getQueryData(queryKey.value)
  //     queryClient.setQueryData(queryKey.value, (oldUsers: AssignTaskUser[]) => {
  //       return oldUsers.map(user =>
  //         user.userId === updatedUser.userId ? { ...user, status: updatedUser.status } : user
  //       )
  //     })
  //     return { previousUsers }
  //   },
  //   onError: (err, updatedUser, context) => {
  //     queryClient.setQueryData(queryKey.value, context?.previousUsers)
  //   },
  //   onSettled: () => {
  //     queryClient.invalidateQueries({ queryKey })
  //   },
  // })

  return {
    users,
    isLoading,
    updateUsers,
    updateUser,
  }
}
