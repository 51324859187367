<script setup lang="ts">
import type { ContentArticle } from '~/models/Content/ContentArticle'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import ArticleTeacherGuide from '~/components/article/ArticleTeacherGuide.vue'

const { article } = defineProps<{ article: ContentArticle }>()

const { t } = useI18n()
const { isTeacher } = storeToRefs(useAuthStore())

const isTeacherGuideOpen = ref(false)
const hasTeacherGuide = computed(() => Number(article.teacherContent?.destinationContentId) > 0)
</script>
<template>
  <div v-if="isTeacher && hasTeacherGuide">
    <button
      class="rounded-3xl border border-coral-60 bg-orange-20 px-6 py-2 font-medium text-coral-60 transition hover:bg-orange-10 focus-visible:ring"
      @click="isTeacherGuideOpen = true"
      v-text="t('article.toTeacher')"
    />
    <ArticleTeacherGuide
      :is-open="isTeacherGuideOpen"
      :content-id="article.teacherContent!.destinationContentId"
      @close-guide="isTeacherGuideOpen = false"
    />
  </div>
</template>
