<script setup lang="ts">
import type { ContentPackage } from '~/models/Content/ContentPackage'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useFilterStore from '~/stores/filter'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import SimpleCard from '~/components/cards/SimpleCard.vue'
import GridCard from '~/components/cards/GridCard.vue'

const props = defineProps<{
  header: ContentPackage
  index: number
}>()

const { gradeFilterFunc } = useFilterStore()
const { isContentHeader } = useContentHelper()
const { findContents } = useContentApi()

const children = computed(() => (data?.value ?? []).filter(gradeFilterFunc))

const isGrid = computed(() => isContentHeader(props.header) && props.header.viewMode === 'grid')
const noImage = computed(() => isContentHeader(props.header) && props.header.viewMode === 'task')

const { data, isLoading } = useQuery({
  enabled: computed(() => !!props.header.mainLocationId),
  queryKey: computed(() => ['header-children', props.header.mainLocationId]),
  queryFn: () => findContents({
    parentLocationIdCriterion: [props.header.mainLocationId],
    sortField: props.header.sortField,
    sortOrder: props.header.sortOrder,
  }, 100),
  staleTime: Infinity,
})
</script>
<template>
  <section class="flex flex-col gap-4">
    <h2
      class="font-tiempos text-4xl"
      v-text="header.title"
    />
    <KsSkeletonWrapper
      v-if="isLoading"
      class="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <KsSkeleton
        v-for="n in 6"
        :key="n"
        height="auto"
        class="aspect-video"
        border-radius="0px"
        background="var(--theme-5)"
      />
    </KsSkeletonWrapper>
    <ul
      v-else
      class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      :class="isGrid ? 'gap-6' : 'gap-[1px]'"
    >
      <li
        v-for="childContent in children"
        :key="childContent.locationId"
        :class="isGrid && 'h-fit'"
      >
        <GridCard
          v-if="isGrid"
          :resource="childContent"
          :loading="index === 0 ? 'eager' : 'lazy'"
        />
        <SimpleCard
          v-else
          :resource="childContent"
          :no-image
        />
      </li>
    </ul>
  </section>
</template>
