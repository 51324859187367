<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { waitFor } from '~/utils/asyncUtils'
import useProductStore from '~/stores/product'
import useLicenseControlStore from '~/stores/licenseControl'
import { useAuthStore } from '~/stores/auth'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSearchHelper from '~/composables/useSearchHelper'
import { useSearchClient } from '~/composables/useSearchClient'
import useFullScreen from '~/composables/useFullscreen'
import useContentMapper from '~/composables/useContentMapper'

const { t } = useI18n()
const router = useRouter()
const { isSupported, isFullscreen, onFullscreenChange, setCurrentFullscreenState } = useFullScreen()
const { hasAccessRestricted } = storeToRefs(useLicenseControlStore())
const { mapLocations, mapContent } = useContentMapper()
const { searchPath, emptyQuery, getLocationIdCriterion } = useSearchHelper()
const { buildResourceUrl } = useUrlBuilder()
const { isAuthenticated } = storeToRefs(useAuthStore())
const { hasProducts } = storeToRefs(useProductStore())
const { getProductFromResource } = useProductStore()
const { results, fetchResults } = useSearchClient<BaseItem[]>(searchPath.value, {
  transformData: (response) => mapLocations(response.View.Result).map(mapContent)
})

const props = withDefaults(defineProps<{
  override?: RouteLocationRaw
  text?: string
  classes?: string | string[]
  variant?: string
  shape?: string
  iconLeft?: string
  size?: string
  item?: BaseItem
  isFixed?: boolean
}>(), {
  classes: () => [],
  variant: 'border',
  shape: 'round',
  iconLeft: 'xmark',
  size: 'large',
  isFixed: true,
})

const homeRoute = router.resolve({ name: 'home' })
const closeRoute = ref<RouteLocationRaw>(homeRoute)
const previousRoute = router.currentRoute.value.meta.returnPath

const hideCloseButton = computed(() => {
  return hasAccessRestricted.value && props.item
})

const product = computed(() => props.item && getProductFromResource(props.item))

const resolveCloseRoute = async (): Promise<RouteLocationRaw> => {
  if (props.override) return props.override
  if (previousRoute) return previousRoute
  if (!props.item) return homeRoute
  if (!props.item?.parentLocationId) return homeRoute
  if (!product.value) return previousRoute || homeRoute

  try {
    await Promise.all([
      fetchResults(emptyQuery, { ...getLocationIdCriterion([props.item.parentLocationId]) }),
      waitFor(() => hasProducts.value, 5000)
    ])
    if (!results.value || results.value.length === 0) return homeRoute

    const parentUrl = buildResourceUrl(results.value[0])

    if (router.resolve(parentUrl).name === 'generic') return homeRoute

    return parentUrl
  } catch (e) {
    console.error(e)
  }
  return homeRoute
}

onMounted(async () => {
  if (!isAuthenticated.value) return
  if (isSupported()) {
    addEventListener('fullscreenchange', onFullscreenChange)
    setCurrentFullscreenState()
  }
  closeRoute.value = await resolveCloseRoute()
})

onUnmounted(() => {
  if (isSupported()) {
    removeEventListener('fullscreenchange', onFullscreenChange)
  }
})
</script>

<template>
  <KsButton
    v-if="isAuthenticated && !hideCloseButton && !isFullscreen"
    :class="[...classes, ...(isFixed ? ['fixed', 'top-4', 'right-4'] : [])]"
    class="z-10 transition duration-200"
    :size="props.size"
    :variant="props.variant"
    :shape="props.shape"
    :aria-label="t('close')"
    :icon-left="props.iconLeft"
    @click="router.push(closeRoute)"
  >
    {{ !!props.text ? text : '' }}
  </KsButton>
</template>
