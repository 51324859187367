<script setup lang="ts">
import type { ContentFlytTask } from '~/models/Content/ContentFlytTask'
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { setTitle } from '~/utils/dom'
import useLicenseControlStore from '~/stores/licenseControl'
import useAuthStore from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import TopBar from '~/components/utils/TopBar.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import FlytTask from '~/components/flyt/FlytTask.vue'
const props = defineProps<{ locationId: number }>()

const { checkItemLicense } = useLicenseControlStore()
const { findContents } = useContentApi()
const { isAuthenticated } = storeToRefs(useAuthStore())

const {
  data: content,
  isError,
} = useQuery({
  staleTime: Infinity,
  queryKey: computed(() => ['flyt-task-view', props.locationId]),
  queryFn: () =>
    findContents<ContentFlytTask>({
      locationIdCriterion: [props.locationId],
      contentTypeCriterion: [ContentType.FlytTask],
    }, 1)
      .then(([content]) => content ?? null)
})

useAppColor(ColorName.Orange10)

watch(content, () => {
  if(!content.value) return
  setTitle(content.value?.title)
  checkItemLicense(content.value)
}, { immediate: true })
</script>

<template>
  <NotFound v-if="isError" />
  <template v-else>
    <header v-if="content">
      <h1
        class="sr-only"
        v-text="content.shortTitle || content.title"
      />
      <TopBar
        variant="white"
        :item="content"
      />
    </header>
    <FlytTask
      v-if="content && isAuthenticated"
      :task-id="content.taskId"
      :mode="content.mode"
      class="mx-1 mb-12 mt-20 rounded-xl bg-[#ffead1ff] p-6 font-inter max-md:px-4 sm:mx-4 lg:mx-auto lg:max-w-[var(--flyt-ideal-content-width-wide)]"
    />
  </template>
</template>
