import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import { lowerPrimaryGrades, lowerSecondaryGrades } from '~/utils/gradeSorter'

export default () => {
  const isComponentView = (subject: SubjectCode, grade: GradeCode) => [
    { subject: 'SAF', grades: lowerSecondaryGrades },
    { subject: 'SPA', grades: lowerSecondaryGrades },
    { subject: 'SAF', grades: lowerPrimaryGrades },
    { subject: 'TVE', grades: lowerPrimaryGrades },
    { subject: 'NAT', grades: lowerPrimaryGrades },
    { subject: 'MAT', grades: lowerPrimaryGrades },
    { subject: 'ENG', grades: lowerPrimaryGrades },
  ].some(({ subject: s, grades }) => subject && grade && s === subject && grades.includes(grade))

  return {
    isComponentView,
  }
}
