<script setup lang="ts">
import { computed, ref, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMutationObserver } from '@vueuse/core'
import { KsButton } from '@aschehoug/kloss'
import { isElement } from '~/utils/dom'

const { t } = useI18n()
const scroller = useTemplateRef('scroller')
const index = ref(0)

const props = defineProps<{
  nav: 'below' | 'floating'
}>()

const navClasses = computed(() => ({
  'flex justify-center p-8': props.nav === 'below',
  'absolute bottom-6 left-1/2 flex -translate-x-1/2 items-center gap-6 transition-opacity': props.nav === 'floating',
}))

let observer: IntersectionObserver

watch((scroller), (el) => {
  if (!el) return

  observer = new IntersectionObserver((entries) => {
    if (!scroller.value) return

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        index.value = [...el.children].indexOf(entry.target)
      }
    })
  }, {
    root: scroller.value,
    rootMargin: '-50%',
  })

  onMutation()
})

function scrollBy(n: number) {
  scroller
    .value
    ?.children
    .item(index.value + n)
    ?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest',
    })
}

function onMutation(mutations?: MutationRecord[]) {
  if (!scroller.value || !observer) return

  if (!mutations) {
    for (const node of scroller.value.childNodes) {
      if (isElement(node)) {
        observer.observe(node)
      }
    }
  }

  else {
    mutations.forEach(({ removedNodes, addedNodes }) => {
      for (const node of removedNodes) {
        if (isElement(node)) {
          observer.unobserve(node)
        }
      }

      for (const node of addedNodes) {
        if (isElement(node)) {
          observer.observe(node)
        }
      }
    })
  }
}

useMutationObserver(scroller, onMutation, { childList: true })
</script>

<template>
  <div class="relative grid max-h-screen">
    <div
      ref="scroller"
      tabindex="0"
      class="relative grid h-full snap-x snap-mandatory auto-cols-max grid-flow-col items-stretch gap-[--carousel-spacing] overflow-y-clip overflow-x-scroll scroll-smooth px-[50%] focus-visible:ring [&>*]:snap-center [&>*]:snap-always"
      :style="{
        scrollbarWidth: 'none',
      }"
      @keydown.left.prevent="scrollBy(-1)"
      @keydown.right.prevent="scrollBy(1)"
    >
      <slot :index />
    </div>
    <nav :class="navClasses">
      <KsButton
        data-name="carousel-nav-left"
        class="carousel-nav-button transition-[opacity,visibility]"
        :class="{ 'pointer-events-none invisible opacity-0': index === 0 }"
        icon-left="arrow-left"
        variant="border"
        shape="round"
        size="large"
        :title="t('previous')"
        @click="scrollBy(-1)"
      />
      <div class="flex items-center self-stretch rounded px-2 tabular-nums">
        <slot
          name="counter"
          :index
        >
          {{ index }}
        </slot>
      </div>
      <KsButton
        data-name="carousel-nav-right"
        class="carousel-nav-button transition-[opacity,visibility]"
        :class="{ 'pointer-events-none invisible opacity-0': index + 1 === scroller?.childElementCount }"
        icon-left="arrow-right"
        variant="border"
        shape="round"
        size="large"
        :title="t('next')"
        @click="scrollBy(1)"
      />
    </nav>
  </div>
</template>
