<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import { lowerSecondaryGrades } from '~/utils/gradeSorter'

const selectedGrades = defineModel<GradeCode[]>()

const { t } = useI18n()
</script>

<template>
  <aside class="grid-in-filters">
    <form
      class="flex flex-col gap-2 rounded bg-white p-3"
      style="--ks-roundness: .2"
    >
      <KsButton
        type="label"
        :variant="selectedGrades?.length === lowerSecondaryGrades.length ? 'secondary' : 'tertiary'"
        class="focus-within:ring"
      >
        <input
          v-model="selectedGrades"
          :value="lowerSecondaryGrades"
          type="radio"
          class="size-3 appearance-none rounded-full bg-white ring ring-blue-40 ring-offset-2 checked:bg-blue-40"
        >
        <span class="ml-2">{{ t('search.filters.allGrades') }}</span>
      </KsButton>
      <KsButton
        v-for="grade in lowerSecondaryGrades"
        :key="grade"
        :variant="selectedGrades?.every(g => g === grade) ? 'secondary' : 'tertiary'"
        type="label"
        class="focus-within:ring"
      >
        <input
          v-model="selectedGrades"
          :value="[grade]"
          type="radio"
          class="size-3 appearance-none rounded-full bg-white ring ring-blue-40 ring-offset-2 checked:bg-blue-40"
        >
        <span class="ml-2">{{ t(`metadata.grades.${grade}`) }}</span>
      </KsButton>
    </form>
  </aside>
</template>
