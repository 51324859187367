<script setup lang="ts">
import type { HeaderGradeTypeProps } from '~/models/Subject'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import { computed, nextTick, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsButton, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import { colorMap } from '~/utils/colors'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import useAuthStore from '~/stores/auth'
import { QueryParamKey } from '~/models/QueryParamKeys'
import { PendoFeature } from '~/models/Pendo'
import { ContentType } from '~/models/Content/ContentType'
import { type ColorName, ColorTheme } from '~/models/Content/Color'
import { useScrollBehavior } from '~/composables/useScrollBehavior'
import { useMedia } from '~/composables/useMedia'
import { useContentHelper } from '~/composables/useContentHelper'
import { useMonoColorTheme } from '~/composables/useColorTheme'
import { useAppColor } from '~/composables/useAppColor'
import useQueryParams from '~/composables/queryParams'
import useContentApi from '~/api/contentApi'
import ScrollButton from '~/components/utils/ScrollButton.vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import HeaderTeacherButton from '~/components/subject/HeaderTeacherButton.vue'
import HeaderFilter from '~/components/subject/HeaderFilter.vue'
import LowerPrimaryHeaderSection from '~/components/subject/gradetypes/LowerPrimaryHeaderSection.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'
import Image from '~/components/media/Image.vue'
import ColorCard from '~/components/cards/ColorCard.vue'

useScrollBehavior()

const props = withDefaults(defineProps<HeaderGradeTypeProps>(), {
  subjectRoute: 'subject',
  isLoading: false,
})

const { t } = useI18n()
const { selectedGrade } = storeToRefs(useFilterStore())
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { isTeacher } = storeToRefs(useAuthStore())
const { set: setAppColor } = useAppColor()
const { findContents } = useContentApi()
const { truthy } = arrayUtils()
const { isContentHeader } = useContentHelper()

const route = useRoute()
const router = useRouter()

const { get: getFilters } = useQueryParams<{ [QueryParamKey.SubHeaders]: number[] }>(QueryParamKey.Filters)

const mediaContentId = computed(() => Number(props.header?.backgroundMedia?.destinationContentId) > 0
  ? [Number(props.header?.backgroundMedia?.destinationContentId)]
  : [])

const { data: bgMedia, isLoading: bgMediaIsLoading } = firstOf(useMedia(mediaContentId))

const { theme } = useMonoColorTheme(props.header?.colorTheme ?? ColorTheme.BlueOrange)
const skeletonColor = computed(() => theme.value[20].rgb)
const dropdownBorderColor = computed(() => theme.value[60].rgb)
const isCollapsable = computed(() => props.header && isContentHeader(props.header) && props.header.collapsible)

const pathLocationIds = computed(() => (props.header?.pathString ?? '/')
  .split('/')
  .map((id) => Number(id))
  .filter(truthy)
  .sort((a, b) => a - b)
  .slice(3))

const showTeacherButton = computed(() => isTeacher.value
  && hasLoadedProducts.value
  && props.header
)

const bannerColor = computed(() => {
  const backgroundColor = props.header?.backgroundColor as ColorName
  return `background: ${colorMap.get(backgroundColor)?.rgb}`
})

const sectionColors = computed(() => {
  if (!props.packages.length) {
    return `background-color: ${theme.value[50].rgb}; color: ${theme.value[20].rgb};`
  } else {
    return props.packages.map((_, index) => {
      const backgroundColor = index % 2 === 0 ? theme.value[60].rgb : theme.value[50].rgb
      const textColor = theme.value.pop.rgb

      return `background-color: ${backgroundColor}; color: ${textColor};`
    })
  }
})

const { data: product, isLoading: productIsLoading } = useQuery({
  staleTime: Infinity,
  queryKey: ['product', pathLocationIds.value],
  enabled: pathLocationIds.value.length > 0,
  queryFn: () => findContents<ContentProduct>({
      locationIdCriterion: pathLocationIds.value,
      contentTypeCriterion: [ContentType.Product],
    }, 1).then(([data]) => data)
})

watch(props, () => {
  if (!props.header) return
  setAppColor(props.header.backgroundColor as ColorName)
}, { immediate: true })

watch([
  () => props.packages,
], () => updateHash(), { immediate: true, deep: true })

function updateHash() {
  const [locationId] = getFilters().shs ?? []
  const index = locationId
    ? props.packages.findIndex((content) => locationId === content.locationId)
    : -1

  if (index < 0) return

  router
    .replace({ ...route, hash: `#${index + 1}` })
    .then(() => nextTick())
    .then(() => {
      setTimeout(() => {
        document
          .getElementById(`${index + 1}`)
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
      }, 100) // wait for transition of accordion
    })
}
</script>

<template>
  <HeaderViewBannerGrid :style="bannerColor">
    <h1
      v-if="props.header"
      class="sr-only"
      v-text="props.header.title"
    />
    <div
      id="top-dropdowns"
      class="flex gap-2 pt-24 grid-in-filters"
      style="--ks-input: white"
    >
      <router-link :to="{ name: props.subjectRoute, params: { subject: subjectCode.toLowerCase() } }">
        <template #default="{ navigate }">
          <KsButton
            variant="secondary"
            icon-left="arrow-left"
            @click="navigate"
          >
            {{ t(`metadata.subjects.${subjectCode}`) }}
          </KsButton>
        </template>
      </router-link>

      <KsSkeleton
        v-if="productIsLoading"
        height="100%"
        width="10rem"
        border-radius="10rem"
        background="#fff7"
      />
      <GradeFilter
        v-else-if="header && product && product.showGradeFilter"
        :grade-codes="header.grades"
      />
    </div>
    <div class="flex flex-col items-start gap-4 self-center pb-24 grid-in-header-info">
      <div class="space-y-2">
        <span
          class="text-sm font-bold uppercase tracking-wider"
          v-text="t('filters.chooseTheme')"
        />
        <HeaderFilter
          v-if="header && selectedGrade"
          id="header-filter"
          :selected="header"
          :options="siblings"
          :subject-code="subjectCode"
          :grade-code="selectedGrade"
          style="--ks-input: white"
          class="w-full"
          navigate
        />
      </div>
      <article
        v-if="props.header?.intro"
        class="prose space-y-2 font-medium text-[currentColor] fluid-text-lg prose-p:my-0 hover:prose-a:text-seagreen-50 focus-visible:prose-a:ring"
      >
        <RichTextRenderer :text="props.header.intro" />
      </article>
      <HeaderTeacherButton
        v-if="showTeacherButton"
        :selected-header="props.header"
        :data-pendo="PendoFeature.TeacherContent.Header"
        variant="secondary"
        :style="{
          '--ks-input': 'rgb(var(--au-yellow-30))',
          '--ks-inputhover': 'rgb(var(--au-yellow-20))',
          'border': '2px solid rgb(var(--au-yellow-60))',
        }"
      />
    </div>
    <Image
      v-if="bgMedia"
      :content="bgMedia"
      :background="bgMediaIsLoading"
      height="0px"
      width="700px"
      class="aspect-video !h-auto min-h-full w-full max-w-full place-self-end object-cover object-left-bottom grid-in-image md:h-0 md:grid-in-[image-top_/_image-top_/_image]"
      :class="!bgMediaIsLoading && 'animate-fade'"
    />
    <CopyrightButton
      v-if="bgMedia && 'copyright' in bgMedia && bgMedia.copyright"
      :copyright="bgMedia.copyright || ''"
      class="m-4 place-self-end row-end-image col-end-image"
    />
  </HeaderViewBannerGrid>
  <template v-if="isLoading">
    <section
      v-for="index in 2"
      :key="index"
      :style="sectionColors[index]"
    >
      <KsSkeletonWrapper class="mx-auto max-w-screen-au space-y-4 px-4 py-8 sm:px-8">
        <KsSkeleton
          height="36px"
          width="120px"
          :background="skeletonColor"
        />
        <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <KsSkeleton
            v-for="i in 4"
            :key="i"
            height="280px"
            :background="skeletonColor"
          />
        </ul>
      </KsSkeletonWrapper>
    </section>
  </template>
  <template v-else>
    <LowerPrimaryHeaderSection
      v-for="(content, index) in packages"
      :key="content.contentId"
      :style="sectionColors[index]"
      :header="content"
      :is-collapsable="isCollapsable"
      :index="index"
      :hash="route.hash"
    />
  </template>
  <section
    v-if="contents.length > 0"
    :style="packages.length
      ? sectionColors[packages.length % sectionColors.length]
      : sectionColors
    "
  >
    <div class="mx-auto flex max-w-screen-au flex-col gap-4 px-4 pb-16 pt-12 sm:px-8">
      <h2
        v-if="packages.length > 0"
        class="font-calistoga fluid-text-2xl"
        v-text="t('labels.other')"
      />
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="content in contents"
          :key="content.locationId"
        >
          <ColorCard :resource="content" />
        </li>
      </ul>
    </div>
  </section>
  <ScrollButton v-if="!isLoading" />
</template>

<style scoped>
:deep(.ks-dropdown .ks-dropdown-list ul) {
  max-height: 620px;
  font-size: 1.15rem;
}

#top-dropdowns :deep(input),
#top-dropdowns button {
  font-size: .9rem;
}

#top-dropdowns :deep(.ks-dropdown svg) {
  font-size: .9rem;
}

#top-dropdowns :deep(.ks-dropdown .ks-dropdown-list ul) {
  font-size: .9rem;
}

#header-filter :deep(input) {
  font-size: 1.4rem;
  padding: .6em;
  height: 2.1em;
  padding-right: 4rem!important;
  font-family: 'Calistoga', serif;
}
#header-filter :deep(svg) {
  top: 15px;
}

#header-filter :deep(button) {
  top: 12px;
}

@media (min-width: 900px) {
  #header-filter :deep(input) {
    font-size: 1.9rem;
    padding: .6em;
    height: 2.1em;
  }
  #header-filter :deep(svg) {
    top: 24px;
  }

  #header-filter :deep(button) {
    top: 21px;
  }
}

#header-filter :deep(input) {
  border: 1px solid v-bind(dropdownBorderColor);
  border-radius: 10px;
}

details::details-content {
  transition: content-visibility .3s allow-discrete;
}
</style>
