<script setup lang="ts">
import type { SortField } from '~/models/Content/Sort'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsSkeleton } from '@aschehoug/kloss'
import { ageGroupMapping, gradeTypeMapping } from '~/utils/gradeSorter'
import SearchSorter from '~/components/search/SearchSorter.vue'

const props = withDefaults(defineProps<{
  criterions: Record<string, string[]>
  isLoading: boolean
  totalCount?: number
  page?: number
  totalPages?: number
  field?: SortField
  groupGradesByAge?: boolean
  groupGradesByGradeType?: boolean
}>(), {
  groupGradesByAge: false,
  groupGradesByGradeType: false
})

const emit = defineEmits(['remove-criterion', 'reset-criterions', 'set-sort-field'])

const { t } = useI18n()

const mappingFunction = computed(() => (props.groupGradesByGradeType ? gradeTypeMapping : ageGroupMapping))

const flattenedCriterions = computed(() => {
  if (!props.groupGradesByAge && !props.groupGradesByGradeType) {
    return Object.entries(props.criterions).flatMap(([type, values]) =>
      values.map(value => ({ type, value }))
    )
  }

  const groupedCriterions: Record<string, { type: string; values: Set<string> }> = {}
  const nonGroupedCriterions: { type: string; value: string }[] = []

  Object.entries(props.criterions).forEach(([type, values]) => {
    values.forEach(value => {
      let isGrouped = false
      for (const [group, keys] of Object.entries(mappingFunction.value)) {
        if (keys.includes(value)) {
          if (!groupedCriterions[group]) {
            groupedCriterions[group] = { type, values: new Set<string>() }
          }
          groupedCriterions[group].values.add(value)
          isGrouped = true
          break
        }
      }
      if (!isGrouped) {
        nonGroupedCriterions.push({ type, value })
      }
    })
  })

  const groupedCriterionsArray = Object.entries(groupedCriterions).flatMap(([group, { type, values }]) => {
    const allKeys = mappingFunction.value[group]
    const allActive = allKeys.every(key => props.criterions[type].includes(key))
    if (allActive) {
      return [{ type, group, values: Array.from(values) }]
    } else {
      return Array.from(values).map(value => ({ type, value }))
    }
  })

  return [...groupedCriterionsArray, ...nonGroupedCriterions]
})

const removeCriterion = (type: string, values: string[] | string) => {
  if (Array.isArray(values)) {
    values.forEach(value => {
      emit('remove-criterion', type, value)
    })
  } else {
    emit('remove-criterion', type, values)
  }
}

const translateGroup = (group: string) => group
  ? (props.groupGradesByGradeType ? t(`search.filterBoxes.${group}`) : group)
  : ''

const translateCriterion = (criterion: { type: string; value: string }) => {
  const translationMap: Record<string, string> = {
    grade: `metadata.grades.${criterion.value}`,
    subject: `metadata.subjects.${criterion.value}`,
    activity: `activities.${criterion.value}`,
    label: `labels.${criterion.value}`,
    access: `access.${criterion.value}`,
    checkbox: `search.checkboxes.${criterion.value}`,
    support: 'search.filterBoxes.support',
  }

  return Object.hasOwn(translationMap, criterion.type)
    ? t(translationMap[criterion.type])
    : criterion.value
}
</script>

<template>
  <div
    class="relative flex flex-wrap items-center gap-4 self-start grid-in-active-filters"
    style="--ks-roundness: .7"
  >
    <div v-if="!isLoading">
      <p
        v-if="totalCount"
        class="flex w-max flex-col text-gray-50"
      >
        <span
          class="text-lg font-bold"
          v-text="t('search.resultsCount', { count: totalCount })"
        />
        <span
          v-if="totalCount > 0"
          class="text-xs"
          v-text="t('search.currentPage', { page: page, totalPages: totalPages > 0 ? totalPages : page })"
        />
      </p>
    </div>
    <KsSkeleton
      v-else
      height="44px"
      width="88px"
      border-radius="0px"
    />
    <ul
      v-if="flattenedCriterions.length"
      class="flex flex-wrap gap-2 pr-12"
    >
      <li
        v-for="criterion in flattenedCriterions"
        :key="criterion.group || criterion.value"
      >
        <KsButton
          icon-right="xmark"
          variant="primary"
          size="small"
          :aria-label="t('search.filters.removeFilter', { filter: translateGroup(criterion.group) || translateCriterion(criterion) })"
          @click="removeCriterion(criterion.type, criterion.values || criterion.value)"
        >
          <span
            class="first-letter:capitalize"
            v-text="translateGroup(criterion.group) || translateCriterion(criterion)"
          />
        </KsButton>
      </li>
      <li v-if="flattenedCriterions.length > 1">
        <KsButton
          icon-right="xmark"
          variant="border"
          size="small"
          :aria-label="t('search.filters.removeAllFilters')"
          @click="emit('reset-criterions')"
        >
          {{ t('search.reset') }}
        </KsButton>
      </li>
    </ul>
    <div
      v-if="field"
      class="absolute right-0 top-1.5"
    >
      <SearchSorter
        :field="field"
        @set-sort-field="emit('set-sort-field', $event)"
      />
    </div>
  </div>
</template>

<style scoped>
.ks-button {
  padding: 0 12px !important;
}
</style>
