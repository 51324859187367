<script setup lang="ts">
import { KsInput } from '@aschehoug/kloss'

const modelValue = defineModel<string>()
</script>

<template>
  <label class="space-y-2">
    <span
      class="text-base font-medium"
      v-text="'Tittel'"
    />
    <KsInput
      v-model="modelValue"
      placeholder="Skriv en tittel på terminprøven..."
      class="!border-2 !border-solid !border-gray-20 !bg-white placeholder:text-gray-30"
    />
  </label>
</template>
