import type { DeepNullable } from '~/models/Utilities'
import type { UserRole } from '~/models/User/UserRole'
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'

export enum TaskStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export enum LocationType {
  Location = 'Location',
  ParentLocation = 'ParentLocation',
}

export type AssignedTaskDetails = DeepNullable<{
  taskId: string
  name: string
  grades: GradeCode[]
  subjects: SubjectCode[]
  groups: string[]
  locationIds: string[]
  startDate: string
  endDate: string
}>

export type AssignTaskDetails = DeepNullable<{
  name: string
  grades: GradeCode[]
  subjects: SubjectCode[]
  groups: string[]
  locationIds: number[]
  locationType: LocationType
  pathString: string
  startDate: string
  duration: number
  tags: string[]
  status: TaskStatus
}>

export type AssignTaskUser = DeepNullable<{
  userId: string
  status: TaskStatus
  firstName: string
  lastName: string
  fullName: string
  role: UserRole
}>


