<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsDrawer, KsIcon } from '@aschehoug/kloss'
import { useAuthStore } from '~/stores/auth'
import TeleportationTarget from '~/models/TeleportationTarget'
import { PendoFeature } from '~/models/Pendo'
import useAppBanner from '~/composables/banner'
import SettingsButton from '~/components/settings/SettingsButton.vue'
import AppLogo from '~/components/AppLogo.vue'

const { t } = useI18n()
const { banner, headerColor } = useAppBanner()
const router = useRouter()
const { isAuthenticated, isLoading, hasOrganization } = storeToRefs(useAuthStore())

const iconBounceStates = ref<{ [key: string]: boolean }>({})

// Method to toggle the bounce state for a specific button
const handleClick = (buttonKey: string, route: string) => {
  iconBounceStates.value[buttonKey] = true
  setTimeout(() => {
    iconBounceStates.value[buttonKey] = false
  }, 700) // Adjust the delay as needed
  router.push({ name: route })
}

const getIconVariant = (routeName: string) => {
  return router.currentRoute.value.name === routeName ? 'solid' : 'regular'
}

/*
*  Do not render two filters at once, this causes duplicate behavior
*  and some strange things to happen because of e.g. duplicate watchers
 */
const windowWidth = ref<number>(0)
onMounted(() => {
  windowWidth.value = window.innerWidth
  window.onresize = () => {
    windowWidth.value = window.innerWidth
  }
})

const showMobileMenu = ref(false)
const goTo = (route: string) => {
  showMobileMenu.value = false
  return router.push({ name: route })
}

watch(windowWidth, () => {
  if (windowWidth.value >= 640 && showMobileMenu.value) {
    showMobileMenu.value = false
  }
})
</script>

<template>
  <header
    v-if="isAuthenticated"
    class="z-30 mx-auto w-full flex-col gap-6 py-6 sm:flex"
    :class="banner ? 'absolute' : 'relative'"
    :style="{
      '--ks-input': banner || isLoading || headerColor ? 'white' : 'rgb(var(--au-secondary))',
      'background': headerColor ?? 'transparent',
    }"
  >
    <nav class="m-auto flex w-full max-w-screen-au items-center justify-between gap-4 px-4 sm:px-8">
      <div class="flex grow basis-0 items-center gap-4">
        <KsButton
          v-ks-tooltip="t('home.title')"
          variant="secondary"
          shape="round"
          size="large"
          :data-pendo="PendoFeature.Navigation.Home"
          @click="handleClick('home', 'home')"
        >
          <KsIcon
            icon="home"
            :bounce="iconBounceStates['home']"
            animation-duration="700ms"
            :variant="getIconVariant('home')"
          />
        </KsButton>
      </div>
      <AppLogo />

      <Teleport :to="TeleportationTarget.AppTop">
        <KsDrawer
          title=" "
          :open="showMobileMenu"
          size="100%"
          @close="showMobileMenu = false"
        >
          <template #body>
            <div class="flex flex-col gap-3">
              <KsButton
                icon-left="magnifying-glass"
                stretch
                size="large"
                :data-pendo="PendoFeature.Navigation.Search"
                @click="goTo('search')"
              >
                {{ t('search.title') }}
              </KsButton>
              <hr
                class="border-gray-20"
                aria-hidden="true"
              >
              <KsButton
                icon-left="heart"
                stretch
                size="large"
                :data-pendo="PendoFeature.Navigation.Favorites"
                @click="goTo('favorites')"
              >
                {{ t('favorite.favoriteListsPageTitle') }}
              </KsButton>
              <hr
                class="border-gray-20"
                aria-hidden="true"
              >
              <KsButton
                v-if="hasOrganization"
                icon-left="calendar-range"
                stretch
                size="large"
                :data-pendo="PendoFeature.Navigation.Planner"
                @click="goTo('plannedResources')"
              >
                {{ t('plan.title') }}
              </KsButton>
              <hr
                class="border-gray-20"
                aria-hidden="true"
              >
              <KsButton
                v-if="hasOrganization"
                icon-left="users"
                stretch
                size="large"
                :data-pendo="PendoFeature.Navigation.StudentActivity"
                @click="goTo('studentActivity')"
              >
                {{ t('activity.pageTitle') }}
              </KsButton>
              <hr
                class="border-gray-20"
                aria-hidden="true"
              >
              <SettingsButton @close="showMobileMenu = false" />
            </div>
          </template>
        </KsDrawer>
      </Teleport>
      <div class="flex grow basis-0 items-center justify-end gap-3 sm:hidden sm:px-0">
        <KsButton
          icon-left="bars"
          variant="secondary"
          shape="round"
          size="large"
          :data-pendo="PendoFeature.Navigation.Burger"
          @click="showMobileMenu = !showMobileMenu"
        />
      </div>
      <div
        v-if="isAuthenticated"
        class="hidden grow basis-0 items-center justify-end gap-3 pl-2 pr-4 sm:flex sm:px-0"
      >
        <KsButton
          v-ks-tooltip="t('search.title')"
          variant="secondary"
          shape="round"
          size="large"
          :data-pendo="PendoFeature.Navigation.Search"
          @click="handleClick('search', 'search')"
        >
          <KsIcon
            :bounce="iconBounceStates['search']"
            icon="magnifying-glass"
            animation-duration="700ms"
            :variant="getIconVariant('search')"
          />
        </KsButton>
        <KsButton
          v-ks-tooltip="t('favorite.favoriteListsPageTitle')"
          variant="secondary"
          shape="round"
          size="large"
          :data-pendo="PendoFeature.Navigation.Favorites"
          @click="handleClick('favorites', 'favorites')"
        >
          <KsIcon
            :bounce="iconBounceStates['favorites']"
            icon="heart"
            animation-duration="700ms"
            :variant="getIconVariant('favorite_lists')"
          />
        </KsButton>
        <KsButton
          v-if="hasOrganization"
          v-ks-tooltip="t('plan.title')"
          variant="secondary"
          shape="round"
          size="large"
          :data-pendo="PendoFeature.Navigation.Planner"
          @click="handleClick('plannedResources', 'plannedResources')"
        >
          <KsIcon
            icon="calendar-range"
            :bounce="iconBounceStates['plannedResources']"
            animation-duration="700ms"
            :variant="getIconVariant('plannedResources')"
          />
        </KsButton>
        <KsButton
          v-if="hasOrganization"
          v-ks-tooltip="t('activity.pageTitle')"
          variant="secondary"
          shape="round"
          size="large"
          :data-pendo="PendoFeature.Navigation.StudentActivity"
          @click="handleClick('studentActivity', 'studentActivity')"
        >
          <KsIcon
            icon="users"
            :bounce="iconBounceStates['studentActivity']"
            animation-duration="700ms"
            :variant="getIconVariant('studentActivity')"
          />
        </KsButton>
        <SettingsButton />
      </div>
    </nav>
  </header>
</template>
