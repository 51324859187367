<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { KsButton } from '@aschehoug/kloss'
import { colors, forceColorShade, getColorForId } from '~/utils/colors'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import AssignTaskDialog from '~/components/products/exams/assignment/AssignTaskDialog.vue'

const { resource } = defineProps<{ resource: BaseItem }>()

const { buildResourceUrl } = useUrlBuilder()

const path = computed(() => buildResourceUrl(resource))
const backgroundColor = computed(() =>
  `rgb(var(${forceColorShade(getColorForId(resource.parentLocationId, colors), '20')}))`)
</script>

<template>
  <article class="group relative size-full rounded-md bg-white p-3 transition">
    <div
      class="pointer-events-none aspect-video overflow-hidden rounded-md"
      :style="{ backgroundColor }"
    >
      <img
        v-if="resource.thumbnail"
        class="size-full rounded-md object-cover"
        :src="resource.thumbnail"
        :alt="resource.thumbnailAlt || ''"
      >
    </div>
    <div>
      <h3
        class="my-3 text-xl font-extrabold text-gray-50"
        v-text="resource.title"
      />
    </div>
    <div class="mt-2 flex flex-col gap-2">
      <AssignTaskDialog />
      <RouterLink
        :to="path"
        class="inline-flex w-full rounded-xl focus-visible:ring"
      >
        <KsButton
          variant="primary"
          size="small"
          type="label"
          stretch
          center
          icon-left="eye"
          class="!text-xs"
        >
          Se prøven
        </KsButton>
      </RouterLink>
    </div>
  </article>
</template>
