import type { MaybeRefOrGetter } from 'vue'
import type { Media } from '~/models/Content/Media'
import { computed, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { Subtree } from '~/models/Content/Subtree'
import useContentApi from '~/api/contentApi'

export function useMedia(contentIds: MaybeRefOrGetter<number[]>) {
  const { findContents } = useContentApi()
  return useQuery({
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 60 * 12,
    enabled: computed(() => toValue(contentIds).some((n) => n > 0)),
    queryKey: computed(() => ['use-media', contentIds]),
    queryFn: () => findContents<Media>({
      contentIdCriterion: toValue(contentIds),
      subtreeCriterion: [Subtree.Content, Subtree.Media],
      mainLocationCriterion: true,
    })
  })
}
