<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsExpand, KsExpandItem, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { colors, forceColorShade, getColorForId } from '~/utils/colors'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'
import StackedCard from '~/components/cards/StackedCard.vue'
import SearchCard from '~/components/cards/SearchCard.vue'

const { exams, selectedGrades, isLoading } = defineProps<{
  exams: ContentProductHeader[]
  selectedGrades: GradeCode[]
  isLoading: boolean
}>()

const { findContents } = useContentApi()

const { data: groupedResources, isLoading: isLoadingResources } = useQuery({
  queryKey: computed(() => ['old_exams_resources', exams.map(r => r.locationId), selectedGrades]),
  queryFn: async () => await findContents<BaseItem>({
    parentLocationIdCriterion: exams.map(r => r.locationId),
    contentTypeCriterion: [ContentType.ProductPackage, ContentType.LearningPath],
    gradeFieldCriterion: selectedGrades,
    gradeOperator: 'or',
    sortField: 'priority',
    sortOrder: 'asc',
  }),
  select: resources => groupResourcesByLocationId(exams, resources),
  enabled: computed(() => !!exams.length),
  staleTime: Infinity,
})

function groupResourcesByLocationId(
  exams: ContentProductHeader[],
  resources: BaseItem[]
) {
  return exams.map(header => ({
    header,
    resources: resources.filter(resource => resource.parentLocationId === header.locationId)
  }))
}
</script>

<template>
  <section
    v-if="!isLoading && !isLoadingResources"
    class="space-y-2"
  >
    <h2 class="text-2xl font-bold">
      Tidligere terminprøver
    </h2>
    <KsExpand size="large">
      <KsExpandItem
        v-for="(group, locationId) in groupedResources"
        :key="locationId"
        :title="group.header.title + ` (${group.resources.length || '0'})`"
      >
        <template #content>
          <ul class="grid grid-cols-1 gap-4 xs:grid-cols-2 lg:grid-cols-3">
            <StackedCard
              v-for="resource in group.resources"
              :key="resource.locationId"
              :class="resource.thumbnail ? 'row-span-2' : 'row-span-1'"
              :item="resource"
              class="size-full"
              :depth="2"
              element="div"
              :stack-offset="3"
              padded-stack
              :colors="[forceColorShade(getColorForId(resource.parentLocationId, colors), '20')]"
            >
              <template #default="{ item }">
                <SearchCard
                  v-if="item"
                  :item="item"
                  package-style
                />
              </template>
            </StackedCard>
          </ul>
        </template>
      </KsExpandItem>
    </KsExpand>
  </section>
  <KsSkeletonWrapper v-else>
    <KsSkeleton
      height="30px"
      width="150px"
    />
    <ul class="mt-2 flex flex-col gap-3">
      <KsSkeleton
        v-for="item in 3"
        :key="item"
        height="60px"
      />
    </ul>
  </KsSkeletonWrapper>
</template>

<style scoped>
:deep(.ks-expand-toggle button) {
  border: 1px solid rgb(var(--au-blue-10));
}
</style>
