<script lang="ts" setup>
import type { ContentAudio } from '~/models/Content/ContentAudio'
import type { AudioTrack } from '~/models/AudioTrack'
import { computed, ref, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton } from '@aschehoug/kloss'
import { rewriteMediaURL } from '~/utils/media'
import { setTitle } from '~/utils/dom'
import useLicenseControlStore from '~/stores/licenseControl'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import { rdfRestClient } from '~/api/client/rdfRestClient'
import CloseButton from '~/components/utils/CloseButton.vue'
import AudioPlayer from '~/components/media/AudioPlayer.vue'

const { checkItemLicense } = useLicenseControlStore()

const props = defineProps<{ locationId: number }>()

const { findContents } = useContentApi()
const { isPlaylist } = useContentHelper()

const { data, isLoading } = useQuery({
  queryKey: ['audio-page', props.locationId],
  queryFn: async () => {
    const [content] = await findContents<ContentAudio>({
      subtreeCriterion: [Subtree.Content, Subtree.Media],
      contentTypeCriterion: [ContentType.Audio],
      locationIdCriterion: [Number(props.locationId)],
    })

    return content
  },
  staleTime: Infinity,
})

const { data: playlist, refetch: refetchPlaylist } = useQuery<AudioTrack[]>({
  queryKey: computed(() => ['audio-view-playlist', props.locationId, data.value?.metadata?.elementURI]),
  queryFn: async () => {
    const url = data.value?.metadata?.elementURI

    if (!url) return null

    return (await rdfRestClient.get(url))
      .data
      ?.results
      ?.bindings
      ?.map((item: any) => ({
        title: item?.title?.value,
        src: rewriteMediaURL(item?.url?.value),
      }))
      ?? null
  },
})

const currentTrack = ref<AudioTrack>()

watch(data, async (value) => {
  if (!value) return

  checkItemLicense(value)
  setTitle(value.title)

  if (isPlaylist(value)) {
    await refetchPlaylist()
    currentTrack.value = playlist.value?.[0]
  }

  else {
    currentTrack.value = {
      title: value.title,
      src: value.metadata?.elementURI,
    }
  }
}, { immediate: true })
</script>

<template>
  <div class="grid h-full grid-cols-[minmax(0,120ch)] grid-rows-[max-content] place-content-center gap-y-6 px-2 py-6 font-inter">
    <template v-if="isLoading">
      <KsSkeleton
        width="33%"
        height="4rem"
      />

      <KsSkeleton
        width="100%"
        height="60px"
      />
    </template>

    <template v-else-if="data?.metadata">
      <CloseButton :item="data" />
      <h1 class="text-5xl font-bold">
        {{ data.title }}
      </h1>

      <AudioPlayer
        v-if="!isPlaylist(data)"
        :src="currentTrack?.src"
        :title.attr="currentTrack?.title"
        style="--media-color: rgb(var(--au-black))"
        load="eager"
      />

      <ul v-else>
        <li
          v-for="track in playlist"
          :key="track.src"
          class="mt-12"
        >
          <div class="font-bold">
            {{ track.title }}
          </div>
          <AudioPlayer
            class="mt-6 w-fit min-w-[40rem] max-w-full"
            :src="track.src"
            :title.attr="track.title"
            style="--media-color: rgb(var(--au-black))"
            load="idle"
            inline
          />
        </li>
      </ul>
    </template>
  </div>
</template>
