<script setup lang="ts">
import type { Component } from 'vue'
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed } from 'vue'
import { ArticleContentVariation } from '~/models/Content/ArticleContentVariation'
import ArticleTextBoxSolid from '~/components/article/TextBoxSolid.vue'
import ArticleTextBoxDashed from '~/components/article/TextBoxDashed.vue'
import ArticleText from '~/components/article/Text.vue'
import BigTextAndRoundImage from './BigTextAndRoundImage.vue'

const props = defineProps<{
  articleContent: ArticleContent
}>()

const componentMap: Record<string, Component> = {
  [ArticleContentVariation.Text]: ArticleText,
  [ArticleContentVariation.TextAndRoundImage]: BigTextAndRoundImage,
  [ArticleContentVariation.TextOnImage]: ArticleText,
  [ArticleContentVariation.TextBoxDashed]: ArticleTextBoxDashed,
  [ArticleContentVariation.TextBoxSolid]: ArticleTextBoxSolid,
  [ArticleContentVariation.TextReflection]: ArticleText,
}

const renderedComponent = computed(() => componentMap[props.articleContent.variation.identifier])
</script>

<template>
  <component
    :is="renderedComponent || ArticleText"
    :article-content="props.articleContent"
  />
</template>
