<script setup lang="ts">
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed } from 'vue'
import { KsSkeleton } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import { useMedia } from '~/composables/useMedia'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import Image from '~/components/media/Image.vue'

const props = defineProps<{
  articleContent: ArticleContent
}>()

const { data: media, isFetching } = firstOf(useMedia([Number(props.articleContent.image?.destinationContentId)]))
const reverse = computed(() => props.articleContent.reverse)
</script>

<template>
  <section class="col-[media] flex items-center gap-[--col-gap] max-md:flex-col">
    <KsSkeleton
      v-if="isFetching"
      class="aspect-square !size-[calc(var(--col-width)*3+var(--col-gap)*2)] flex-none !rounded-full"
      :class="{
        'md:order-2': !reverse,
      }"
    />
    <div
      v-else-if="media"
      class="relative size-[calc(var(--col-width)*3+var(--col-gap)*2)] flex-none"
      :class="{
        'md:order-2': !reverse,
      }"
    >
      <Image
        v-if="media"
        :content="media"
        class="aspect-square !h-full rounded-full object-cover"
        width="400px"
      />
      <CopyrightButton
        v-if="'copyright' in media && media.copyright"
        :copyright="media.copyright"
        class="!absolute bottom-0 right-0"
      />
    </div>
    <div class="au-prose au-subtitle font-semibold">
      <RichTextRenderer :text="articleContent.body" />
    </div>
  </section>
</template>
