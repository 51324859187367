<script lang="ts" setup>
import type { FavoriteCreateInput, FavoriteList, UpToDateFavoriteList } from '~/models/Favorites'
import type { BaseItem } from '~/models/Content/BaseItem'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { KsIcon } from '@aschehoug/kloss'
import { getColorForId, forceColorShade, popThisColor, colors } from '~/utils/colors'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import { useFavorites } from '~/composables/useFavorites'
import ScrollBox from '~/components/utils/ScrollBox.vue'
import FavoriteDialog from '~/components/favorites/FavoriteDialog.vue'
import StackedCard from '~/components/cards/StackedCard.vue'


const { t } = useI18n()

const props = defineProps<{
  item: BaseItem
}>()

const emit = defineEmits<{
  addToList: [{ item: FavoriteCreateInput; list: FavoriteList }]
}>()

const { getProductFromResource } = useProductStore()
const { userPreferredLanguage } = storeToRefs(useAuthStore())
const { favoriteLists } = useFavorites()
const { data: lists } = favoriteLists

const addToList = (item: BaseItem, list: FavoriteList) => {
  emit('addToList', {
    item: {
      name: item.title,
      hidden: false,
      aunivers: {
        contentId: item.contentId,
        locationId: item.locationId,
        language: userPreferredLanguage.value,
        grade: item.grades,
        subject: getProductFromResource(item)?.subjects || [],
      },
    },
    list,
  })
}
</script>


<template>
  <div>
    <div class="-m-6 mt-0 pb-10">
      <ScrollBox class="h-[40dvh] px-6">
        <ul class="grid grid-cols-[max-content,auto,max-content] gap-4">
          <li
            v-for="(list) in lists"
            :key="`favorite-list-${list.id}`"
            class="col-span-3 grid grid-cols-subgrid"
          >
            <button
              v-if="list.id"
              class="group col-span-3 -mx-2 grid cursor-pointer grid-cols-subgrid rounded-md p-2 hover:bg-[var(--favorite-custom-bg)] focus-visible:ring"
              :style="`--favorite-custom-bg: rgb(var(${popThisColor(getColorForId(list.id, colors))}));`"
              @click="addToList(props.item, list)"
            >
              <StackedCard
                class="grid aspect-square"
                :colors="[forceColorShade(getColorForId(list.id, colors), '10')]"
                :depth="list.favorites?.length ?? 0"
                :max-depth="4"
                :padded-stack="true"
                :stack-offset="2"
                element="div"
              >
                <div class="self-center text-center">
                  <span
                    :style="`color: rgb(var(${getColorForId(list.id, colors)})); filter: brightness(40%)`"
                    v-text="list.favorites?.length ?? 0"
                  />
                </div>
              </StackedCard>
              <span
                class="self-center text-start"
                v-text="list.name"
              />
              <KsIcon
                v-if="list.favorites?.some((favorite) => Number(favorite?.aunivers?.locationId) === item.locationId)"
                :icon="faCheck"
                class="mr-2 self-center justify-self-end"
              />
              <div
                v-else
                :data-pendo="PendoFeature.Favorites.Add"
                class="invisible mr-2 self-center justify-self-end rounded-md bg-green-50 px-3 py-1 text-white transition-none group-hover:visible"
              >
                {{ t('save') }}
              </div>
            </button>
          </li>
        </ul>
      </ScrollBox>
      <FavoriteDialog
        action="create"
        :item="item"
      >
        <template #activator>
          <button class="-mb-10 grid w-full cursor-pointer grid-cols-[max-content,minmax(0,1fr)] items-center gap-5 rounded-b-2xl bg-seagreen-5 p-6 text-start focus-visible:ring">
            <KsIcon
              icon="plus"
              class="justify-self-start rounded-full !bg-white p-4"
            />
            <span
              class="text-xl"
              v-text="t('favorite.createListButtonName')"
            />
          </button>
        </template>
      </FavoriteDialog>
    </div>
  </div>
</template>
