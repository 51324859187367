import type { BaseItem } from '~/models/Content/BaseItem'
import type { RelationField, RelationsField, TaxonomyField } from '~/models/Content/BaseField'

export enum ArticleSize {
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

export enum ArticleHeaderVariation {
  Simple = 'simple',
  Color = 'color',
  TextAndImage = 'text_and_image',
  TextOnImage = 'text_on_image',
}

export enum ArticleColorTheme {
  Violet = 'violet',
  Blue = 'blue',
  Green = 'green',
  Coral = 'coral',
}

export interface ContentArticle extends BaseItem {
  body?: string
  activity?: string
  wordlist: string[]
  size: TaxonomyField<ArticleSize>
  image?: RelationField
  authors?: RelationsField
  content?: RelationsField
  colorTheme: TaxonomyField<ArticleColorTheme>
  headerVariation: TaxonomyField<ArticleHeaderVariation>
  teacherContent?: RelationField
}
