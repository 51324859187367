<script setup lang="ts">
import type { Group, GroupMember } from '~/models/Group'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { faArrowTurnRight } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog, KsSpinner } from '@aschehoug/kloss'
import { UserRole } from '~/models/User/UserRole'
import TeleportationTarget from '~/models/TeleportationTarget'
import { useGroupMembers } from '~/composables/useGroupMembers'
import StudentSelectionList from '~/components/products/exams/assignment/StudentSelectionList.vue'
import NameInput from '~/components/products/exams/assignment/NameInput.vue'
import GroupSelectionDropdown from '~/components/products/exams/assignment/GroupSelectionDropdown.vue'

const { t } = useI18n()

const open = ref(false)
const taskName = ref('')
const selectedGroups = ref<Group[]>([])
const selectedStudents = ref<GroupMember[]>([])

const {
  members: students,
  isLoading
} = useGroupMembers(selectedGroups, UserRole.Student)

const shareExam = () => {
  open.value = false
}
</script>

<template>
  <KsButton
    variant="secondary"
    :icon-left="faArrowTurnRight"
    size="small"
    stretch
    center
    class="!text-xs"
    @click="open = true"
  >
    Start tildeling
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="t('assign.shareWithStudents')"
      :open
      @close="open = false"
    >
      <template #body>
        <div
          class="flex flex-col gap-4"
          style="--ks-roundness: .6"
        >
          <NameInput v-model="taskName" />
          <GroupSelectionDropdown v-model="selectedGroups" />
          <StudentSelectionList
            v-if="!isLoading"
            v-model:students="students"
            v-model:selected-students="selectedStudents"
            :selected-groups
            @close-modal="open = false"
          />
          <KsSpinner v-else />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end gap-4">
          <KsButton
            variant="secondary"
            @click="open = !open"
          >
            {{ t('cancel') }}
          </KsButton>
          <KsButton
            variant="primary"
            :disabled="!selectedStudents.length"
            @click="shareExam"
          >
            {{ t('assign.openExam') }}
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
