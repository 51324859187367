<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { colorMap, getColorAndShade } from '~/utils/colors'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useContentHelper } from '~/composables/useContentHelper'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

const props = withDefaults(defineProps<{
  resource: BaseItem
  isAnchor?: boolean
  themeColor: string
  loading?: 'eager' | 'lazy'
}>(), {
  loading: 'lazy',
})

const { getTitle } = useContentHelper()
const { buildResourceUrl } = useUrlBuilder()
const { t } = useI18n()

const title = computed(() => getTitle(props.resource))
const link = computed(() => buildResourceUrl(props.resource))
const backgroundColor = computed(() =>
  `bg-${colorMap.get(getColorAndShade(props.themeColor, '60'))?.name}/80`)
</script>

<template>
  <article
    class="group relative h-full rounded-md text-lg motion-safe:animate-fade-in-down"
    :style="{
      '--ring-offset-color': colorMap.get(getColorAndShade(props.themeColor, '60'))?.rgb,
      '--ring-color': colorMap.get(getColorAndShade(props.themeColor, '10'))?.rgb,
    }"
  >
    <div
      ref="elementRef"
      class="relative aspect-[4/3] h-full max-w-full overflow-hidden rounded-md"
    >
      <aside
        v-if="resource.new"
        class="pointer-events-none absolute right-0 top-0 z-20 rounded-bl-2xl bg-green-40 p-1.5 pl-2 text-sm font-medium uppercase tracking-wider text-white"
        v-text="t('newShort')"
      />
      <img
        v-if="resource.thumbnail"
        :src="resource.thumbnail"
        class="block size-full rounded-md object-cover object-bottom"
        :loading
        :alt="resource.thumbnailAlt"
        aria-hidden="true"
      >
      <div
        v-else
        class="absolute inset-0 bg-blue-50"
      />
    </div>
    <div class="absolute bottom-0 z-10 flex size-full flex-col items-center justify-end rounded-2xl bg-gray-50/10 text-center text-white">
      <div
        class="h-max w-full rounded-b-md px-5 py-6"
        :class="backgroundColor"
      >
        <span class="line-clamp-3 overflow-hidden text-xl font-semibold text-white lg:text-2xl">
          <LinkOrAnchor
            :link="link"
            class="outline-0 before:absolute before:inset-0 before:z-10 before:rounded-md before:shadow-md before:ring-offset-2 before:ring-offset-[--ring-offset-color] before:transition before:hover:shadow-lg hover:before:ring hover:before:ring-[--ring-color] before:focus-visible:shadow-lg before:focus-visible:ring"
            :is-anchor="props.isAnchor"
          >
            {{ title }}
          </LinkOrAnchor>
        </span>
      </div>
    </div>
  </article>
</template>
