import type { Ref } from 'vue'
import type { UserRole } from '~/models/User/UserRole'
import type { Group } from '~/models/Group'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import useGroupsStore from '~/stores/groups'

export function useGroupMembers(selectedGroups: Ref<Group[]>, roleFilter?: UserRole) {
  const { loadGroupMembers, findGroupMembers } = useGroupsStore()

  const fetchGroupMembers = async (groups: Group[]) => {
    await Promise.all(groups.map(loadGroupMembers))

    const uniqueStudents = new Set<string>()
    const allMembers = groups.flatMap(findGroupMembers)

    return allMembers.filter(student =>
      !uniqueStudents.has(student.userId) && uniqueStudents.add(student.userId))
  }

  const { data: members, isLoading, isError } = useQuery({
    queryKey: computed(() => ['group-members', selectedGroups.value, roleFilter]),
    queryFn: () => fetchGroupMembers(selectedGroups.value),
    select: members => roleFilter
      ? members.filter(member => member.role === roleFilter)
      : members,
    enabled: computed(() => selectedGroups.value.length > 0),
    staleTime: Infinity,
  })

  return { members, isLoading, isError }
}
