<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'

const { isTeacher } = storeToRefs(useAuthStore())
</script>

<template>
  <div
    v-if="isTeacher"
    class="flex items-center justify-center border-4 border-dotted border-blue-20 p-16 text-xl text-blue-30"
  >
    Delte terminprøver vil dukke opp her! <br>
  </div>
  <div v-else>
    Du har ingen tildelte terminprøver enda.
  </div>
</template>

<style scoped>

</style>
