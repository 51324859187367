<script setup lang="ts">
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed } from 'vue'
import { KsSkeleton } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import { useMedia } from '~/composables/useMedia'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import Image from '~/components/media/Image.vue'

const props = defineProps<{
  articleContent: ArticleContent
}>()

const { data: media, isFetching } = firstOf(useMedia([Number(props.articleContent.image?.destinationContentId)]))
const reverse = computed(() => props.articleContent.reverse)
</script>

<template>
  <div
    class="grid grid-cols-subgrid items-center gap-y-[--col-width] bg-[--color-light] p-[--col-gap]"
    :class="media ? 'col-[media] py-[--col-width]' : 'col-[text] md:-mx-[--col-gap]'"
  >
    <KsSkeleton
      v-if="isFetching"
      class="col-span-5 row-start-1"
      :class="reverse ? '-col-end-3' : 'col-start-3'"
      height="300px"
    />
    <div
      v-else-if="media"
      class="relative col-[extext] row-start-1"
      :class="reverse ? 'md:col-start-[midpoint]' : 'md:col-end-[midpoint]'"
    >
      <Image
        :content="media"
        class="max-h-[90lvh] !w-full object-contain"
        width="400px"
      />
      <div
        v-if="'copyright' in media && media.copyright"
        class="flex flex-col bg-white p-4"
      >
        <RichTextRenderer :text="media.caption" />
        <small v-if="media.copyright">{{ $t('copyright.image', { copyright: media.copyright }) }}</small>
      </div>
    </div>

    <div
      class="au-prose col-[1/-1] md:row-start-1"
      :class="{
        'md:col-[extext/midpoint]': (media || isFetching) && reverse,
        'md:col-[midpoint/extext]': (media || isFetching) && !reverse,
      }"
    >
      <RichTextRenderer :text="articleContent.body" />
    </div>
  </div>
</template>
