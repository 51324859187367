import type { TeacherArticle } from '~/models/TeacherArticle'
import type { SubjectCode } from '~/models/Subject'
import type { Product } from '~/models/Product'
import type { GradeCode } from '~/models/Grade'
import type { FiltersQueryParams } from '~/models/Filter'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import type { ContentResource } from '~/models/Content/ContentResource'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import type { ContentPdfBook } from '~/models/Content/ContentPdfBook'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { ContentLink } from '~/models/Content/ContentLink'
import type { ContentFile } from '~/models/Content/ContentFile'
import type { ContentAudio } from '~/models/Content/ContentAudio'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { ImageVariations } from '~/models/Content/BaseField'
import { computed, unref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import { sortByGradeIndex, upperSecondaryGrades } from '~/utils/gradeSorter'
import useArrayUtils from '~/utils/arrayUtils'
import { i18n } from '~/translations'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import router from '~/router'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import { QueryParamKey } from '~/models/QueryParamKeys'
import useSubjectView from '~/composables/useSubjectView'
import { ContentType } from '~/models/Content/ContentType'
import useSlugify from '~/composables/useSlugify'
import useProductHelper from '~/composables/useProductHelper'
import { useContentHelper } from '~/composables/useContentHelper'
import useQueryParams from '~/composables/queryParams'

/**
 * Composable for building URLs for resources. Some resources should get a link to the CMS view,
 * some should be sent to a subject view, some should be sent to a package view, and some should be
 * sent to a product view. This composable handles all of that.
 *
 * Some rules to keep in mind:
 * - If we are in package or product view, we must not send to subject page
 * - If we are in subject view, we may send to package or product view
 * - If there is a grade filter in the URL, we must use that grade filter in the URL we build
 *    - If there is no content in the view for the grade from the filter in the URL, the user will
 *      be asked to select a grade that has content
 * - When a URL is linking to a subject page, we must not set a subject filter for the same URL
 *   - This means no URLs like /fag/nor?f={"s": "NOR"}
 * - When we are in a subject view, and have selected to show resources from a different subject,
 *   the generated URLs have to point to the _current_ subject and not the subject for the resource.
 */
export const useUrlBuilder = () => {
  const { slugify } = useSlugify()
  const { get, getUrl } = useQueryParams<FiltersQueryParams>(QueryParamKey.Filters)
  const route = useRoute()
  if (!route) {
    const url = window.location.pathname
    const errorMessage = `urlBuilder composable was used outside of setup context on ${url}`
    throw new Error(errorMessage)
  }

  const { getProductFromResource } = useProductStore()
  const { isAddonProduct, isSearchableProduct, isSupportProduct } = useProductHelper()
  const { activeUserGroup, userPreferredLanguage, userRelevantGrades, isTeacher, userPreferredGrade } = storeToRefs(useAuthStore())
  const { currentSubject } = storeToRefs(useSubjectsStore())
  const { isComponentView } = useSubjectView()
  const { truthy, intersect } = useArrayUtils()
  const { selectedGrade } = storeToRefs(useFilterStore())
  const {
    isPackage,
    isFile,
    isUploadedFile,
    isLearningPath,
    isBookReader,
    isFlashcards,
    isArticle,
    isPresentation,
    isPlannedItem,
    isLink,
    isProduct,
    isContentResource,
    isFlytTask,
    isVideo,
    isPdfBook,
    isPdf,
    isTeacherArticle,
    isChatbot,
    isHeader,
    isTermExam,
  } = useContentHelper()

  const inSubjectView = route && route.name === 'subject'

  const canParseURL = (href: string) => {
    try {
      new URL(href)
      return true
    } catch (e) {
      return false
    }
  }

  const buildReturnPathParam = (url: string, returnPath?: string) => {
    const [mainUrl, urlParams] = url.split('?')
    const params = new URLSearchParams(urlParams)
    if (!returnPath) returnPath = router.currentRoute.value.fullPath
    try {
      params.set('r', window.btoa(`app::${returnPath}`))
    } catch (e) {
      console.warn(`Error encoding returnPath ${returnPath}`)
      console.warn(e)
    }
    return [mainUrl, params]
  }

  const buildResourceUrl = (resource: BaseItem | PlannedItem | ContentResource | ContentLink | ContentFile | ContentPackage | ContentProduct, returnPath?: string): string => {
    if (isUploadedFile(resource)) return resource.url
    if (isLink(resource)) return resource.url
    if (isFile(resource)) return buildFileUrl(resource)
    if (isPackage(resource)) return buildPackageUrl(resource)
    if (isProduct(resource)) return buildProductRoute(resource)
    if (isBookReader(resource)) return buildBookReaderUrl(resource)
    if (isFlashcards(resource)) return buildFlashcardsUrl(resource)
    if (isPresentation(resource)) return buildPresentationUrl(resource)
    if (isFlytTask(resource)) return buildFlytTaskUrl(resource)
    if (isVideo(resource)) return buildVideoUrl(resource)
    if (isPdfBook(resource)) return buildPdfBookUrl(resource)
    if (isTeacherArticle(resource)) return buildTeacherArticleUrl(resource)
    if (isArticle(resource)) return buildArticleUrl(resource)
    if (isChatbot(resource)) return buildChatbotUrl(resource)

    const url = (resource as ContentResource | PlannedItem)?.url ?? getDefaultUrl(resource)
    const [mainUrl, params] = buildReturnPathParam(url, returnPath)
    return `${mainUrl}?${params}`
  }

  const buildLearningPathUrl = (resource: BaseItem, overrideLocationId?: number, returnPath?: string): string => {
    if (!overrideLocationId) return buildResourceUrl(resource, returnPath)
    if (!isLearningPath(resource)) return buildResourceUrl(resource, returnPath)

    const url = isPlannedItem(resource) ? resource.url : getDefaultUrl(resource)
    const [, params] = buildReturnPathParam(url, returnPath)
    return `${import.meta.env.VITE_AUNIVERS_SITEURL}/location/${overrideLocationId}/${resource.locationId}?${params}`
  }

  const formWriterRoute = (baseUrl: string, userId: string): string => {
    const url: URL = new URL(baseUrl)
    url.searchParams.set('gruppe', activeUserGroup.value?.groupId || '')
    url.searchParams.set('elev', userId || '')

    return url.href
  }

  const getDefaultUrl = (item: BaseItem) => `${import.meta.env.VITE_AUNIVERS_SITEURL}/location/${item.locationId}`

  const buildPackageUrl = (item: ContentPackage | ContentResource): string => {
    const parentType = item.parentLocationContentTypeIdentifier
    const product = getProductFromResource(item)
    if (!product) return buildPackageRoute(item)

    if (isSearchableProduct(product)) {
      if (parentType === ContentType.TermExam) return buildTermExamUrl(item, product)
      return buildPackageInProductRoute(item, product)
    }

    const hasSubjectGrades = selectedGrade.value && product.subjects.length > 0

    if (isHeader(item) && hasSubjectGrades && isComponentView(product.subjects[0], selectedGrade.value!)) {
      return buildHeaderUrl(item, product.subjects[0], selectedGrade.value!)
    }

    // sometimes we don't have a proper pathString, so we have to check for that
    if ((!item.pathString || item.pathString.split('/').length <= 3)) {
      return buildPackageRoute(item)
    }

    const filter = buildFilter(item) ?? {}
    const filterSubject = (filter[QueryParamKey.Subject] ?? '').toUpperCase() as SubjectCode
    const filterGrade = (filter[QueryParamKey.Grade] ?? '').toLowerCase() as GradeCode
    const [filterHeaderLocationId] = filter[QueryParamKey.Headers] ?? []

    if (filterSubject && !inSubjectView) {
      delete filter[QueryParamKey.Subject]
    }

    if (filterSubject && currentSubject.value && filterSubject === currentSubject.value.toUpperCase()) {
      delete filter[QueryParamKey.Subject]
    }

    let baseUrl = resolveBaseUrl(product, item, filterSubject, filterGrade, filterHeaderLocationId)
    if (!route) return buildPackageRoute(item)
    const isGenericView = ['generic', 'product', 'package'].includes(route?.name as string)

    if (isGenericView) {
      // GenericView is currently used for both package and product views. In those cases, we can't
      // use subject for the URL and we have to use the current URL with filter query params
      baseUrl = route.path
      delete filter[QueryParamKey.Subject]
    }

    const { [QueryParamKey.Grade]: queryParamGrade } = get()
    if (queryParamGrade) {
      filter[QueryParamKey.Grade] = queryParamGrade
    }

    return getUrl(filter, baseUrl)
  }

  const buildRoute = (name: string, params: Record<string, string|number|string[]>) =>
    router.resolve({ name, params }).href

  const buildProductRoute = (item: ContentProduct) => {
    // Upper secondary products should route to the legacy/default app
    if (item.grades.every((grade) => upperSecondaryGrades.includes(grade))) {
      return getDefaultUrl(item)
    }

    const storeProduct = getProductFromResource(item)
    if (storeProduct
      && !isAddonProduct(storeProduct)
      && !isSupportProduct(storeProduct)
      && item.subjects.length > 0
    ) {
      return buildRoute('subject', {
        subject: item.subjects[0].toLowerCase(),
      })
    }

    return buildRoute('product', {
      locationId: item.locationId,
      slug: slugify(item.title),
    })
  }

  const buildPackageRoute = (item: BaseItem) =>
    buildRoute('package', {
      locationId: item.locationId,
      slug: slugify(item.title),
  })

  const buildPackageInProductRoute = (item: BaseItem, product: Product) =>
    buildRoute('product_package', {
      locationId: item.locationId,
      productLocationId: product.aunivers.locationId,
      slug: slugify(item.title),
      productSlug: slugify(product.aunivers.name),
  })

  const buildParentPackageRoute = (locationId: number, title: string, product?: Product) => {
    const params: Record<string, string|number> = {
      locationId,
      slug: slugify(title),
    }

    const routeName = product ? 'product_package' : 'package'

    if (product) {
      params.productLocationId = product.aunivers.locationId
      params.productSlug = slugify(product.aunivers.name)
    }

    return buildRoute(routeName, params)
  }

  function resolveBaseUrl(product: Product, content?: ContentPackage | ContentResource, subject?: SubjectCode, grade?: GradeCode, headerLocationId?: number): string {

    const isUpcomingSubject = computed(() => router.currentRoute.value?.name === 'subject_upcoming')
    const subjectCode = subject && subject.toLowerCase()
    const isHeaderView = content && subject && grade && (isComponentView(subject, grade) || isUpcomingSubject.value) && headerLocationId


    if (isAddonProduct(product)) {
      return router.resolve({
        name: 'product',
        params: {
          locationId: product.aunivers.locationId,
          slug: slugify(product.aunivers.name),
        },
      }).fullPath
    }

    if (isHeaderView) {
      return router.resolve({
        name: 'header',
        params: {
          subject: subjectCode,
          locationId: headerLocationId,
          slug: slugify(content.title),
        },
      }).href
    }

    if (subject) {
      return router.resolve({
        name: 'subject',
        params: {
          subject: subjectCode,
        },
      }).href
    }

    return ''
  }

  const buildFileUrl = (item: ContentFile | PlannedItem, forceLanguage?: PreferredLanguage): string => {
    if (isPlannedItem(item) || isContentResource(item)) {
      const [, params] = buildReturnPathParam(item.url)
      return `${item.url}?${params}`
    }

    if (item.fileUrl) {
      return item.fileUrl
    }

    let uri = item?.file?.uri || ''
    if (canParseURL(uri)) {
      const parsedURL = new URL(uri)
      uri = parsedURL.pathname
    }

    const language = forceLanguage ?? userPreferredLanguage.value

    if (language === PreferredLanguage.Nynorsk) {
      uri = `/${PreferredLanguage.Nynorsk}${uri}`
    }

    return `${import.meta.env.VITE_AUNIVERS_SITEURL}${uri}`
  }

  const buildAudioUrl = (item: ContentAudio, forceLanguage?: PreferredLanguage): string => {
    if (item.metadata?.elementURI) {
      return item.metadata.elementURI
    }

    if (!item.file?.uri) return '#'

    let uri = item.file.uri
    const language = forceLanguage ?? userPreferredLanguage.value

    if (language === PreferredLanguage.Nynorsk) {
      uri = `/${PreferredLanguage.Nynorsk}${uri}`
    }

    return `${import.meta.env.VITE_AUNIVERS_SITEURL}${uri}`
  }

  const buildPdfBookUrl = (item: ContentPdfBook): string => {
    if (!item.metadata?.elementURI) {
      console.warn(`Missing elementURI in content ${item.contentId}`)
      return '#'
    }

    if (isPdf(item) && isTeacher.value) {
      return router.resolve({
        name: 'pdf',
        params: {
          locationId: item.locationId,
          slug: slugify(item.title),
        },
      }).fullPath
    }

    return item.metadata.elementURI
  }

  const buildTeacherArticleUrl = (item: TeacherArticle): string => {
    return router.resolve({
      name: 'teacher_article',
      params: {
        locationId: item.locationId,
        slug: slugify(item.title),
      },
    }).fullPath
  }

  const buildFilter = (item: BaseItem): Nullable<FiltersQueryParams> => {
    const { pathString } = item
    if (!pathString) return null

    const product = getProductFromResource(item)

    if (!product) return null

    const { subjects } = product
    const [subject] = [...subjects].sort(sortBySubjectIndex)

    const availableGrades = userPreferredGrade.value ? [userPreferredGrade.value] : userRelevantGrades.value

    const [grade] = intersect<GradeCode>(item.grades, availableGrades).sort(sortByGradeIndex)
    const productMetadata = product.aunivers ?? {}
    const { relatedLocations, locationId: productLocationId } = productMetadata

    const root = Object.values(relatedLocations ?? {})
      .find((locationId) => pathString.includes(`/${locationId}/`)) ?? `/${productLocationId}/`

    const headers = pathString.split(root.toString())[1]
      .split('/').map((number: string) => Number(number))
      .filter(truthy)

    let subHeaders: number[] | undefined

    /**
     * Because addon products should not use a subject or grade filter, we simply return the headers
     */
    if (isAddonProduct(product)) return {
      ...(headers ? { [QueryParamKey.Headers]: headers } : {}),
    }


    if (isComponentView(subject, grade) && Array.isArray(headers) && headers.length > 1) {
      subHeaders = [headers.pop() as number]
      console.warn('[AU2 warn] We skip the deepest header for new subject view. The header we skipped was', subHeaders)
    }

    return {
      ...(subject ? { [QueryParamKey.Subject]: subject } : {}),
      ...(headers ? { [QueryParamKey.Headers]: headers } : {}),
      ...(subHeaders ? { [QueryParamKey.SubHeaders]: subHeaders } : {}),
      ...(grade ? { [QueryParamKey.Grade]: grade } : {}),
    }
  }

  const buildImageUrlByField = (
    contentId: number,
    fieldDefinitionId: string = 'image',
    variation: ImageVariations = 'thumbnail_large',
  ) => {
    const locale = unref(i18n.global.locale)
    const localePath = locale === PreferredLanguage.Bokmal ? '' : `/${locale}`

    return `${import.meta.env.VITE_AUNIVERS_SITEURL}${localePath}/bilde/${contentId}/${fieldDefinitionId}/${variation}`
  }

  const buildSvgUrlByField = (contentId: number) =>
    `${import.meta.env.VITE_AUNIVERS_SITEURL}/image/svg/${contentId}/svg_file/svg-${contentId}.svg`

  const buildBookReaderUrl = (resource: BaseItem) => {
    return router
      .resolve({
        name: 'bookreader_summary',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title),
        },
      })
      .fullPath
  }

  const buildFlashcardsUrl = (resource: BaseItem) => {
    return router
      .resolve({
        name: 'flashcards',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title),
        },
      })
      .fullPath
  }

  const buildPresentationUrl = (resource: BaseItem): string => {
    return router
      .resolve({
        name: 'presentation',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title || ''),
        },
      })
      .
      fullPath
  }

  const buildVideoUrl = (resource: BaseItem): string => {
    return router
      .resolve({
        name: 'video',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title),
        },
      })
      .fullPath
  }

  const buildFlytTaskUrl = (resource: BaseItem): string => {
    return router.resolve({
      name: 'flyt_task',
      params: {
        locationId: resource.locationId,
        slug: slugify(resource.title),
      },
    })
      .fullPath
  }

  const buildArticleUrl = (resource: BaseItem) => {
    return router
      .resolve({
        name: 'article',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title),
        },
      })
      .fullPath
  }

  const buildChatbotUrl = (resource: BaseItem) => {
    return router
      .resolve({
        name: 'chatbot',
        params: {
          locationId: resource.locationId,
          slug: slugify(resource.title),
        },
      })
      .fullPath
  }

  const buildTermExamUrl = (resource: ContentPackage|ContentResource, product: Product) => {
    return router
      .resolve({
        name: 'task_header',
        params: {
          locationId: resource.locationId,
          parentLocationId: resource.parentLocationId,
          productLocationId: product.aunivers.locationId,
          slug: slugify(resource.title),
          parentSlug: slugify(resource.parentLocationName),
          productSlug: slugify(product.aunivers.name),
        },
      })
      .fullPath
  }

  const buildHeaderUrl = (content: BaseItem, subjectCode: SubjectCode, gradeCode: GradeCode) => {
    const grade = !content.grades.length || content.grades.includes(gradeCode) ? gradeCode : content.grades[0]
    return router
      .resolve({
        name: 'header',
        params: {
          subject: subjectCode.toLowerCase(),
          locationId: content.mainLocationId,
          slug: slugify(content.title),
        },
        query: { grade: grade ?? gradeCode }
      }).fullPath
  }

  return {
    buildFileUrl,
    resolveBaseUrl,
    inSubjectView,
    buildResourceUrl,
    formWriterRoute,
    buildFilter,
    buildPackageUrl,
    buildParentPackageRoute,
    buildLearningPathUrl,
    buildImageUrlByField,
    buildSvgUrlByField,
    buildAudioUrl,
    buildHeaderUrl,
    buildTermExamUrl,
  }
}
